.radio-container {

  &__title {
    margin      : 0;
    padding     : var(--radio-title-padding, 7px 0);
    font-family : inherit;
    font-size   : 1.5rem;
    font-weight : 600;
    line-height : 1.8;
    color       : $colorFontCommon;
    }

  &__description {
    margin        : 0;
    padding       : var(--radio-description-padding, 0 0 7px);
    font-family   : $fontFamilySecondary;
    font-size     : $fontSizeSecondarySmall;
    font-weight   : $fontWeightSecondaryRegular;
    line-height   : 1;
    color         : $colorFontCommon;
    overflow-wrap : break-word;

    [data-device=mobile] & {
      font-size   : 1.4rem;
      font-weight : $fontWeightSecondaryRegular;
      }
    }

  &__content {
    &__option {
      position : relative;

      &__icon {
        display     : flex;
        position    : absolute;
        align-items : center;
        height      : 100%;
        left        : 12px;
        overflow    : hidden;

        &__container {
          position       : relative;
          content        : "";
          width          : 2rem;
          height         : 2rem;
          overflow       : hidden;
          transition     : 0s ease-in-out 0s;
          border-radius  : 50%;
          outline        : 0.2rem solid $colorFontLight;
          outline-offset : -0.2rem;

          &--selected {outline-color : $colorAccentBasic;}

          &--selected &__circle::before {
            width            : 0.2rem;
            height           : 0.2rem;
            transition       : 400ms ease-in-out 200ms, width 200ms ease-in 0ms, height 200ms ease-in 0ms, outline-color 0s ease-in 0s, outline-width 200ms ease-in-out 200ms;
            outline          : 0.5rem solid $colorAccentBasic;
            outline-offset   : -0.1rem;
            background-color : $colorAccentBasic;
            }

          &__circle {
            display         : flex;
            position        : absolute;
            justify-content : center;
            align-items     : center;
            width           : 100%;
            height          : 100%;

            &::before {
              display          : block;
              content          : "";
              width            : 2rem;
              height           : 2rem;
              transition       : 400ms ease-in-out 0ms, outline-width 200ms ease-in-out 0s, height 200ms ease-in-out 200ms, width 200ms ease-in-out 200ms, outline-color 0s ease 0s, outline-color 0ms ease-in-out 400ms;
              border-radius    : 50%;
              outline          : 0.9rem solid $colorFontLight;
              //outline          : 0.9rem solid transparent;
              outline-offset   : -0.2rem;
              background-color : transparent;
              }
            }

          &--disabled,
          &--disabled &__circle::before {

            [data-theme=dark] & {outline-color : #383838;}
            [data-theme=light] & {outline-color : #b1b1b1;}
            }
          }

        &--disable-animations {
          .radio-container__content__option__icon__container {
            &__circle,
            &__circle::before,
            &--selected &__circle,
            &--selected &__circle::before {
              transition : 0ms ease-in-out 0ms;
              }
            }
          }
        }

      &__button {
        .ripple-button {
          padding-left : 18px;
          &__title {
            padding-left   : 25px;
            font-weight    : 600;
            text-align     : left;
            text-transform : initial;
            }
          }
        }
      }
    }
  }