.switch-button {
  display         : flex;
  align-items     : center;
  width           : 100%;
  max-width       : 100%;
  height          : auto;
  margin          : 0;
  padding         : 0;
  border          : none;
  background      : none;
  box-shadow      : none;

  --switch-width  : 4.3rem;
  --switch-height : 2.4rem;
  --point-padding : 0.5rem;

  &:focus {
    outline    : none;
    background : none;
    }

  &:hover {
    background : none;
    }

  &__title-container {
    flex-grow      : 1;
    min-width      : 0;
    padding-right  : 30px;
    font-family    : $fontFamilySecondary;
    text-align     : left;
    text-transform : initial;

    &__title {
      margin        : 0;
      font-family   : $fontFamilySecondary;
      font-size     : $fontSizeSecondary;
      font-weight   : $fontWeightSecondaryBold;
      line-height   : 1.2;
      color         : $colorFontTitle;
      overflow-wrap : break-word;

      [data-device=mobile] & {
        font-size   : $fontSizeSecondaryLarge;
        font-weight : $fontWeightSecondaryRegular;
        }
      }

    &__description {
      margin        : 0;
      padding-top   : 3px;
      font-family   : $fontFamilySecondary;
      font-size     : $fontSizeSecondarySmall;
      font-weight   : $fontWeightSecondaryRegular;
      line-height   : 1;
      color         : $colorFontCommon;
      overflow-wrap : break-word;

      [data-device=mobile] & {
        font-size   : 1.4rem;
        font-weight : $fontWeightSecondaryRegular;
        }
      }
    }

  &__settings-container {
    position      : relative;
    padding-right : 5px;

    &::after {
      position         : absolute;
      content          : "";
      width            : 1px;
      height           : calc(100% - 16px);
      top              : 8px;
      right            : 0;
      background-color : $colorFontDisabled;
      }

    &__button {
      width  : 30px;
      height : 30px;

      &:hover {
        --buttonColorBasicLight : var(--colorAccentBasic) !important;
        --buttonColorBasicDark  : var(--colorAccentBasic) !important;
        }

      .ripple-button {
        width  : 30px;
        height : 30px;
        &__icon {
          font-size : 1.7rem;
          }
        }
      }
    }

  &__indicator {
    display     : flex;
    position    : relative;
    flex-shrink : 0;
    align-items : center;
    height      : auto;
    margin      : 3px 0 3px 10px;
    padding     : 0;
    border      : none;
    background  : none;
    box-shadow  : none;

    &:focus-visible {
      border-radius : calc(var(--switch-height) / 2);
      outline       : 2px solid $colorAccentContrast;
      }

    &:hover &__background {
      [data-theme=light] & {background-color : $colorAccentLight}
      [data-theme=dark] & {background-color : $colorAccentBasic}
      }

    &:focus-visible &__point {
      border-radius  : calc(var(--switch-height) / 2);
      outline        : 2px solid $colorAccentContrast;
      outline-offset : -4px;
      }

    &__background {
      width         : var(--switch-width);
      height        : var(--switch-height);
      transition    : $transitionDefault;
      border-radius : calc(var(--switch-height) / 2);
      [data-theme=light] & {background-color : #bfbfbf}
      [data-theme=dark] & {background-color : #717171}
      }

    &__point {
      position      : absolute;
      width         : calc(var(--switch-height) - var(--point-padding) * 2);
      height        : calc(var(--switch-height) - var(--point-padding) * 2);
      top           : var(--point-padding);
      left          : var(--point-padding);
      transition    : $transitionDefault;
      border-radius : 50%;
      [data-theme=light] & {background-color : #fff}
      [data-theme=dark] & {background-color : #e0e0e0}
      }
    }
  }

.switch-button--checked {
  --point-padding : 0.3rem;

  &:hover .switch-button__indicator__background {
    [data-theme=light] & {background-color : $colorAccentBasic}
    [data-theme=dark] & {background-color : $colorAccentLight}
    }

  .switch-button__indicator {

    &__background {
      [data-theme=light] & {background-color : $colorAccentBasic}
      [data-theme=dark] & {background-color : $colorAccentLight}
      }

    &__point {
      left : calc(var(--switch-width) - var(--switch-height) + var(--point-padding));
      [data-theme=light] & {background-color : $colorAccentSoft}
      [data-theme=dark] & {background-color : #fff}
      }
    }
  }

.switch-button--disabled {

  &:hover .switch-button__indicator__background {
    [data-theme=light] & {background-color : #a7a7a7}
    [data-theme=dark] & {background-color : #464646}
    }

  .switch-button {
    &__title-container {
      &__title, &__description {
        [data-theme=light] & {color : $colorFontDisabled;}
        [data-theme=dark] & {color : $colorFontDisabled;}
        }
      }

    &__settings-container {
      &__button {
        .ripple-button {
          cursor : initial;
          }
        }
      }

    &__indicator {
      cursor : initial;

      &__background {
        [data-theme=light] & {background-color : #a7a7a7}
        [data-theme=dark] & {background-color : #464646}
        }

      &__point {
        [data-theme=light] & {background-color : #c3c3c3}
        [data-theme=dark] & {background-color : #656565}
        }
      }
    }
  }