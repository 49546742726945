@import '../../../../../../styles/variables';

.header-dropdown {
  position  : relative;
  min-width : 0;

  &__ripple {
    display         : flex;
    justify-content : center;
    align-items     : center;
    height          : 100%;

    &__trigger {
      display     : flex;
      position    : relative;
      align-items : center;
      width       : 100%;
      min-width   : 0;
      height      : 100%;
      padding     : 5px 15px;
      cursor      : pointer;
      transition  : .1s ease-in;
      border      : none;
      background  : none;
      z-index     : 1;

      &:hover &__text {color : $colorFontLight;}
      &:hover &__icon, &:focus-visible &__icon {
        color      : $colorFontLight;
        opacity    : 1;
        visibility : visible;
        }

      &:focus-visible {
        transition     : 0s ease;
        outline        : 1px solid $colorFontInfo;
        outline-offset : -1px;
        }

      &__text {
        overflow       : hidden;
        font-family    : $fontFamilyPrimary;
        font-size      : 1.3rem;
        font-weight    : 700;
        white-space    : nowrap;
        text-transform : uppercase;
        text-overflow  : ellipsis;
        color          : $colorFontTitle;
        }

      &__icon {
        display         : flex;
        position        : absolute;
        justify-content : center;
        align-items     : center;
        width           : 1.4rem;
        height          : 1.4rem;
        bottom          : 0.8rem;
        left            : calc(100% / 2 - 0.7rem);
        transition      : $transitionDefault;
        font-size       : 12px;
        color           : $colorFontTitle;
        opacity         : 0;
        visibility      : hidden;

        > svg {
          width       : 12px;
          }
        }
      }
    }

  &__content {
    display          : flex;
    position         : absolute;
    flex-flow        : column;
    padding          : 0.8rem 0;
    top              : calc(100% - 1.2rem);
    left             : -0.8rem;
    transition       : $transitionDefault;
    transform        : scale(0.8);
    transform-origin : top left;
    border-radius    : 0.8rem;
    background-color : $colorBackgroundTop;
    opacity          : 0;
    visibility       : hidden;
    box-shadow       : 0 0 0.1rem rgba(0, 0, 0, 0.04), 0 0 2rem 0.4rem rgba(0, 0, 0, 0.15);
    z-index          : 998;

    &__link {

      &:hover {text-decoration: none;}

      &__button {
        :global .ripple-button {

          &:focus-visible {outline : 1px solid $colorFontInfo;}

          &__title {
            padding        : 0 2rem 0 1.2rem;
            font-size      : 1.5rem;
            font-weight    : 700;
            text-align     : left;
            text-transform : initial;
            }
          }
        }
      }
    }
  }

.header-dropdown--open {

  .header-dropdown__ripple {
    &__trigger {
      &__icon {
        transform  : rotate(-180deg);
        opacity    : 1;
        visibility : visible;
        }
      }
    }

  .header-dropdown__content {
    transform  : scale(1);
    opacity    : 1;
    visibility : visible;
    }

  :global .ripple-layout::before {
    background-color : var(--rippleLayoutColorRippleTint);
    opacity          : 0.2;
    }

  :global .ripple-layout:hover::before {
    background-color : var(--rippleLayoutColorRippleTint);
    opacity          : 0.2;
    }
  }